const PATHS = {
  root: '/',
  terms: 'terms',
  leaderBoard: 'leader-board',
  privacyPolicy: 'privacy-policy',
  // Users
  users: 'users',
  businessTeam: 'business-team',
  technicalTeam: 'technical-team',
  companies: 'companies',
  players: 'players',
  // Settings
  settings: 'settings',
  accountSettings: 'account-settings',
  accountSubscriptions: 'account-subscriptions',
  accountTransactions: 'account-transactions',
  paymentMethods: 'payment-methods',
  addPaymentCard: 'settings/add-payment-card',
  // Auth
  login: 'login',
  register: 'register',
  verifyEmail: 'auth/verify/:token',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password/:token',
  userProfile: ':username',
  accountConnection: 'connect/:token',
  // Invitations
  invite: 'invite',
  acceptInvitation: 'invite/register/:token',
  // companies
  createCompany: 'users/companies/create',
  editCompany: ':companyName/edit',
  companyUsers: ':companyName/members',
  // Plans
  plans: 'plans',
  viewPlan: 'plans/:planId',
  plansList: 'plans-list',
  pendingPlans: 'pending-plans',
  createPlan: 'plans/create',
  editPlan: 'plans/:planId/edit',
  // Labs
  labs: 'labs',
  viewLab: ':labId',
  premiumLabs: 'premium-labs',
  proLabs: 'pro-labs',
  trainingLabs: 'training-labs',
  competitiveLabs: 'competitive-labs',
  createLab: 'labs/create',
  // events
  events: 'events',
  viewEvent: ':eventId',
  visualization: 'visualization',
  eventVisualization: 'events/:eventId/visualization',
  createEvent: 'events/create',
  editEventTeam: ':teamId/edit',
  viewEventTeam: ':teamId',
  details: 'details',
  teams: 'teams',
  invitations: 'invitations',
  // Challenges
  challenges: 'challenges',
  viewChallenge: 'challenges/:challengeId',
  createChallenge: 'challenges/create',
  // Machines
  machines: 'machines',
  createMachine: 'machines/create',
  viewMachine: 'machines/:machineName',
  editMachine: 'machines/:machineName/edit',
  // PromoCode
  promoCodes: 'promo-codes',
  viewPromoCode: 'promo-codes/:promoCodeId',
  promoCodeList: 'promo-codes-list',
  createPromoCode: 'promo-codes/create',
  // Subscriptions
  subscriptions: 'subscriptions',
  checkout: 'subscriptions/checkout',
  // Profile
  profile: 'profile/:username',
  overview: 'overview',
  badges: 'badges',
  activity: 'activity',
  // Announcements
  announcements: 'announcements',
  createAnnouncement: 'announcements/create',
  viewAnnouncement: 'announcements/:announcementId',
  editAnnouncement: 'announcements/:announcementId/edit',
  // Instances
  instances: 'instances',
  // Forms
  hitbctf: 'forms/hitbctf',
  hitbVisitors: 'forms/hitbvisitors',
  // CyberNights
  cyberNight: 'cybernights6',
};

export default PATHS;
